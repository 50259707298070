import { useContext } from 'react';
import styled from 'styled-components';
import Link from 'next/link';

import NavigationContext from '@/contexts/navigation';
import useButtonTracking from '@/utils/useButtonTracking';

const Item = ({ label, icon, url, subLabel, close, open, ...props }) => {
  const { setIsOpen } = useContext(NavigationContext);
  const trackClick = useButtonTracking({ label, url, location: 'navbar' });

  return (
    <Box open={open}>
      <Link
        data-testid="item-link"
        href={url}
        passHref
        {...(props?.target === '_blank' ? { rel: 'noopener noreferrer' } : {})}
        {...props}
      >
        <Content
          onClick={() => {
            trackClick();
            setIsOpen(false);
            close();
          }}
        >
          <Icon className="icon" data-testid="item-icon">
            {icon}
          </Icon>
          <Info>
            <Title data-testid="item-label">{label}</Title>
            {subLabel && <Label data-testid="item-subLabel">{subLabel}</Label>}
          </Info>
        </Content>
      </Link>
    </Box>
  );
};

export default Item;

const Box = styled.div`
  &:first-child {
    transition:
      opacity 0.4s 0.05s,
      transform 0.4s 0.05s,
      background 0.3s;
  }
  &:nth-child(2) {
    transition:
      opacity 0.4s 0.1s,
      transform 0.4s 0.1s,
      background 0.3s;
  }
  &:nth-child(3) {
    transition:
      opacity 0.4s 0.15s,
      transform 0.4s 0.15s,
      background 0.3s;
  }
  &:nth-child(4) {
    transition:
      opacity 0.4s 0.2s,
      transform 0.4s 0.2s,
      background 0.3s;
  }
  &:nth-child(5) {
    transition:
      opacity 0.4s 0.25s,
      transform 0.4s 0.25s,
      background 0.3s;
  }
  &:nth-child(6) {
    transition:
      opacity 0.4s 0.3s,
      transform 0.4s 0.3s,
      background 0.3s;
  }
  &:nth-child(7) {
    transition:
      opacity 0.4s 0.35s,
      transform 0.4s 0.35s,
      background 0.3s;
  }
  &:nth-child(8) {
    transition:
      opacity 0.4s 0.4s,
      transform 0.4s 0.4s,
      background 0.3s;
  }
  &:nth-child(9) {
    transition:
      opacity 0.4s 0.45s,
      transform 0.4s 0.45s,
      background 0.3s;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    display: flex;
    border-radius: 4px;
    padding: 20px 19px 24px;
    transform: translateY(-20px);
    background-color: #fff;
    min-width: 300px;

    > * {
      transform: translateY(0);
    }
    &:hover {
      background-color: #faf8f7;
      .icon {
        background-color: #f2edea;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 32px;
    padding-left: 24px;
    transform: translateX(-20px);
    opacity: 0;
    > * {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    display: flex;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.neutral.default};
  transition: background-color 0.3s ease;
  min-width: 40px;

  svg {
    width: 25px;
    height: 25px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

const Info = styled.div`
  text-align: left;
  color: ${(props) => props.theme.colors.grey.dark};
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    margin-left: 16px;
  }
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.font.family.heading};

  font-size: 16px;
  line-height: 24px;
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    margin: 8px 0;
    font-weight: 700;
  }
`;

const Label = styled.div`
  font-family: ${(props) => props.theme.font.family.body};
  max-width: 309px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

export { Box };
