import { useContext } from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import NavigationContext from '@/contexts/navigation';

export const Logo = styled(Link)`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  border: none;
  box-shadow: none;
  background-color: transparent;
  width: 100%;
  z-index: 1000;
  height: 22px;

  img {
    height: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    height: 100%;

    img {
      height: 27px;
    }
  }
`;

const LogoWrapper = ({ dynamicNavbarLayout, className }) => {
  const { setIsOpen, scrolled, hover, isOpen } = useContext(NavigationContext);

  return (
    <Logo
      href="/"
      data-testid="logo-button"
      aria-label="Go to homepage"
      className={className}
      onClick={() => {
        setIsOpen(false);
      }}
    >
      {!dynamicNavbarLayout || scrolled || hover || isOpen ? (
        <img
          data-testid="logo-img"
          className="logo"
          src="/assets/logo/logo__colored.svg"
          alt="Sword Health Digital MSK solutions"
          width={110}
          height={27}
        />
      ) : (
        <img
          data-testid="logo-img"
          className="logo"
          src="/assets/logo/logo__white.svg"
          alt="Sword Health Digital MSK solutions"
          width={110}
          height={27}
        />
      )}
    </Logo>
  );
};

export default LogoWrapper;
